import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { RESULTADO_DOCUMENTOS, RESULTADO_FIRMA } from 'app/mock-api/apps/firma-formulario-afiliacion/data';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class FirmaFormularioAfiliacionMockApi
{
    private _documentos: any = RESULTADO_DOCUMENTOS;
    private _firma: any = RESULTADO_FIRMA;

    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        if(environment.mocks){
            this.registerHandlers();
        }
    }

    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Activities - GET
        // -----------------------------------------------------------------------------------------------------        
        this._fuseMockApiService
            .onGet('afiliacion/afiliacion/firma/documentos?email=joserai.ramirezl@gmail.com')
            .reply(() => [201, cloneDeep(this._documentos)]);

        // -----------------------------------------------------------------------------------------------------
        // @ Activities - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onPost('afiliacion/afiliacion/firma/digital')
            .reply(() => [201, this._firma]);
    }

}
