import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingBackdropService {
    private open = false;

    constructor() { }

    // Shows the loading backdrop
    show() {
        if (this.open) {
            return;
        }
        const backdropWrapperElement = this.createLoadingBackdropTemplate();
        const bodyElement = document.querySelector('body');
        bodyElement?.appendChild(backdropWrapperElement);
        this.open = true;
    }

    // Hides the loading backdrop
    hide() {
        const backdropWrapperElement =
            document.querySelector('#loadingBackdrop');
        backdropWrapperElement?.remove();
        this.open = false;
    }

    // Creates the loading backdrop template
    private createLoadingBackdropTemplate(): HTMLDivElement {
        const element = document.createElement('div');
        element.setAttribute('id', 'loadingBackdrop');
        element.setAttribute(
            'style',
            `
                width: 100vw;
                height: 100vh;
                display: grid;
                place-items: center;
                position: fixed;
                inset: 0;
                z-index: 1901;
            `
        );
        element.innerHTML = `
        <div class="backdrop"></div>
        <div class="spinner-focum">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
        `;
        return element;
    }
}
