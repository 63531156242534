import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from 'app/app.module';

declare global {
    interface Window {
        Apex: any;
    }
}
platformBrowserDynamic().bootstrapModule(AppModule)
                        .catch(err => console.error(err));
