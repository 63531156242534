<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="!isSmallScreen">
    <fuse-vertical-navigation
        class="dark bg-primary print:hidden"
        [mode]="'over'"
        [name]="'mainNavigation'"
        [navigation]="navigation?.default"
        [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img
                    class="w-24"
                    src="assets/images/logo/logo-text-on-dark.svg"
                    alt="Logo">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-primary dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isSmallScreen">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8">
                <div class="hidden lg:flex">
                    <img alt="logo"
                        class="dark:hidden w-24 cursor-pointer"
                        src="assets/images/logo/logo-color.png" routerLink="/home">
                    <img alt="logo"
                        class="hidden dark:flex w-24 cursor-pointer"
                        src="assets/images/logo/logo-color.png" routerLink="/home">
                </div>
                <img alt="logo"
                    class="flex lg:hidden w-8 cursor-pointer"
                    src="assets/images/logo/logo-color.png" routerLink="/home">
            </div>
            <!-- Menu principal -->
            <fuse-horizontal-navigation 
                class="ml-auto"
                [name]="'mainNavigation'"
                *ngIf="navigation && navigation.horizontal !== undefined"
                [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </ng-container>


        <!-- Navigation toggle button -->
        <ng-container *ngIf="!isSmallScreen">
            <button  title="Navegacion"
                mat-icon-button
                (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:menu'" class="text-white"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex px-4 ml-20 space-x-0.5 sm:space-x-2" *ngIf="navigation && navigation.horizontal !== undefined">

            <notifications class="text-white"></notifications>
            
            <user [showAvatar]="false"></user>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex  items-center justify-center w-full h-14 sm:h-10 px-5 md:px-6 z-49 border-t bg-black dark:bg-transparent print:hidden" *ngIf="!isSmallScreen">
        <span class="font-medium text-white px-4 md:px-6">Políticas de cookies</span>
        <span class="font-medium text-white px-4 md:px-6">Políticas de privacidad</span>
        <span class="font-medium text-white px-4 md:px-6">Términos legales</span>
        <span class="font-medium text-white px-4 md:px-6">Términos y condiciones</span>
    </div>

</div>
