<!-- Notifications toggle -->
<button mat-icon-button (click)="openPanel()" #notificationsOrigin>
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span
                class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-teal-600 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div
        class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-120 sm:w-120 overflow-hidden shadow-lg z-99999">

        <!-- Header -->
        <div class="flex-col shrink-0 items-center py-4 pr-4 pl-6 bg-white text-black">
            <div class="text-2xl font-medium leading-snug">Tus notificaciones</div>
            <div class="text-lg font-medium leading-snug text-gray-600"> {{unreadCount}} nuevas</div>

        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let notification of notifications; trackBy: trackByFn">
                <div class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5">
                    <ng-container>
                        <a class="flex flex-auto py-5 pl-6 cursor-pointer" (click)="filtraAfiliacion(notification)">
                            <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                        </a>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-4 ml-2">
                        <div class="mt-2 text-xs leading-none text-primary">
                            {{notification.fecha_creacion}}
                        </div>
                        <button class="w-6 h-6 min-h-6 ml-auto text-primary  cursor-pointer" mat-icon-button
                            (click)="delete(notification)" [matTooltip]="'Eliminar'" type="button"
                            title="Borrar notificación">
                            <mat-icon class="icon-size-4 text-primary" [svgIcon]="'heroicons_solid:trash'"></mat-icon>
                        </button>
                    </div>
                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <div class="flex flex-col flex-auto  cursor-pointer">
                        <ng-container *ngIf="notification.mensaje">
                            <div class="line-clamp-2 font-bold" [innerHTML]="notification.asunto"></div>
                            <div class="line-clamp-2" [innerHTML]="notification.mensaje"></div>
                        </ng-container>

                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="unreadCount < 1">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div
                        class="flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-600">
                        <mat-icon class="text-primary-700 dark:text-primary-50"
                            [svgIcon]="'heroicons_outline:bell'"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">No tiene notificaciones</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">Cuando tenga notificaciones,
                        aparecerán aquí.
                    </div>
                </div>
            </ng-container>

        </div>

        <div *ngIf="unreadCount > 0" class="z-99999 bg-card">
            <div class="mx-5 my-5 flex z-99999">
                <div class="text-gray-500 text-sm"><span (click)="markAllAsRead()" class="cursor-pointer"
                        (keydown)="markAllAsReadHandleKeydown($event)">
                        > Marcar todas como leídas</span></div>
                <div class="ml-auto text-primary text-sm">
                    <a (click)="verTodas()" class="cursor-pointer">Ver todas&nbsp;&nbsp;&nbsp;&nbsp;<span class="text-xs cursor-pointer">▶</span></a>
                </div>
            </div>
        </div>

    </div>

</ng-template>