import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
} from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { Api } from '@core/models/Api.model';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat)

@Injectable({
    providedIn: 'root',
})
export class AfiliacionService {
    private progressSources: Map<string, BehaviorSubject<number>> = new Map();

    constructor(private _http: HttpClient) {}

    // Method to create an affiliation
    crearAfiliacion(body: any): Observable<any> {
        return this._http.post<any>(
            `${Api.urlAfiliacion}/crear_afiliacion/`,
            body
        );
    }

    // Method to create a massive affiliation
    crearAfiliacionMasivo(id: string, body: any): Observable<any> {
        this.getProgressSource(id).next(0); // Reset progress
        const req = this._http
            .post<any>(
                `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva/`,
                body,
                {
                    observe: 'events',
                    reportProgress: true,
                }
            )
            .pipe(
                map((event) => this.updateProgress(id, event)),
                catchError(this.handleError)
            );
        return req;
    }

    // Method to create massive beneficiaries
    crearBeneficiariosMasivo(id: string, body: any): Observable<any> {
        this.getProgressSource(id).next(0); // Reset progress
        const req = this._http
            .post<any>(
                `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva_beneficiarios/`,
                body,
                {
                    observe: 'events',
                    reportProgress: true,
                }
            )
            .pipe(
                map((event) => this.updateProgress(id, event)),
                catchError(this.handleError)
            );
        return req;
    }

    // Method to delete massive affiliations
    borrarMasivo(id_masivo: string): Observable<any> {
        return this._http.delete<any>(
            `${Api.BaseUrlAfiliacion}/afiliacion/borrar-masivo/${id_masivo}/`
        );
    }

    // Method to verify an Excel file
    verificarExcel(hash: string): Observable<any> {
        return this._http.post<any>(
            `${Api.BaseUrlAfiliacion}/afiliacion/verificar_carga_masiva/`,
            { hash }
        );
    }

    // Method to get progress source
    getProgressSource(id: string): BehaviorSubject<number> {
        if (!this.progressSources.has(id)) {
            this.progressSources.set(id, new BehaviorSubject<number>(0));
        }
        return this.progressSources.get(id);
    }

    // Method to upload affiliation documents massively
    subirArchivosAfiliacionMasivo(
        id: string,
        body: any[],
        isBenef = false
    ): Observable<any> {
        this.getProgressSource(id).next(0);
        const uploadData = JSON.stringify(body);

        const req = this._http
            .post<any>(
                `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva/documentos_empleado/?beneficiarios=${isBenef}`,
                uploadData,
                {
                    observe: 'events',
                    reportProgress: true,
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                    }),
                }
            )
            .pipe(
                map((event) => this.updateProgress(id, event)),
                catchError(this.handleError)
            );

        return req;
    }

    // Method to update progress
    private updateProgress(id: string, event: HttpEvent<any>): any {
        switch (event.type) {
            case HttpEventType.UploadProgress: {
                const percentDone = Math.round(
                    (100 * event.loaded) / event.total
                );
                this.getProgressSource(id).next(percentDone);
                return { type: 'progress', progress: percentDone };
            }
            case HttpEventType.Response:
                return { type: 'response', body: event.body };
        }
    }

    // Method to handle errors
    private handleError(error: any) {
        console.error(error);
        return throwError(error);
    }

    // Method to download example
    downloadExample(modelo: number = 1): Observable<any> {
        return this._http.get<any>(
            `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva/modelo/?modelo=${modelo}`
        );
    }

    // Method to get affiliation history
    getHistorialAfiliaciones(): Observable<any> {
        return this._http
            .post<any>(`${Api.urlAfiliacion}/afiliacion_historial/`, {
                nombre_masivo_afiliacion_identificador: null,
                fecha_ingreso: null,
            })
            .pipe(map((resp) => {
                const { objeto: { manuales: data_manuales, masivos: data_masivos } } = resp;

                // Asignar nuevos valores para filtrar registros
                const manuales = data_manuales.map((i: any) => ({
                    ...i,
                    es_masivo: false,
                    conteo_afiliaciones: '1/1'
                }));

                // Asignar nuevos valores para filtrar registros
                const masivos = data_masivos.map((i: any) => ({
                    ...i,
                    es_masivo: true,
                    conteo_afiliaciones: `${i.correctas || 0}/${(i.correctas || 0) + (i.incorrectas || 0)}`
                }));

                // Concatenar los registros
                const registros = [...manuales, ...masivos];

                // Transformar las fechas
                const data = registros.map((i: any) => ({
                    ...i,
                    fecha_registro: dayjs(i.fecha_registro, 'DD/MM/YYYY').format('DD-MM-YYYY'),
                    hora_registro: dayjs(i.hora_registro, 'hh:mm a').format('hh:mm a'),
                    fecha_hora_registro: dayjs(`${i.fecha_registro} ${i.hora_registro}`, 'DD/MM/YYYY hh:mm a'),
                }));

                // Ordenar los registros.
                const results = data.sort((a: any, b: any) => {
                    const a_fecha = dayjs(a.fecha_hora_registro).unix();
                    const b_fecha = dayjs(b.fecha_hora_registro).unix();

                    return b_fecha - a_fecha;
                })

                return results;
            }));
    }

    getDocumentosFirmaAfilicacion(
        id: number,
        signed: boolean = false
    ): Observable<any> {
        if (signed) {
            return this._http.get<any>(
                `${Api.urlAfiliacion}/afiliacion/firma/documentos/${id}/${signed}/`
            );
        }
        return this._http.get<any>(
            `${Api.urlAfiliacion}/afiliacion/firma/documentos/${id}`
        );
    }

    // Method to download example
    validarSiExisteAfiliado(datos: any): Observable<any> {
        return this._http.post<any>(
            `${Api.BaseUrlAfiliacion}/afiliacion/validar_afiliacion/`, datos
        );
    }

}
