import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { RESULTADOS } from 'app/mock-api/apps/control-afiliacion/documento/data';

@Injectable({
    providedIn: 'root'
})
export class DocumentoMockApi
{
    private _documento: any = RESULTADOS;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void
    {
        // -----------------------------------------------------------------------------------------------------
        // @ Activities - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('/FiltroAfiliacionesDocumento?eps=true&arl=true&ccf=true&afp=true')
            .reply(() => [200, cloneDeep(this._documento)]);
    }
}
