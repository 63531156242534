/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id   : 'example',
        title: 'Example',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id   : 'home-afilicacio',
        title: 'Afiliación',
        type : 'basic',
        link : '/home/afiliacion'
    },
    {
        id   : 'control-afiliacion',
        title: 'Contratación',
        type : 'basic',
        link : '/home/afiliacion'
    },
    {
        id   : 'example',
        title: 'Incapacidades',
        type : 'basic',
        link : '/home/afiliacion'
    },
    {
        id   : 'example',
        title: 'Reportes',
        type : 'basic',
        link : '/home/afiliacion'
    },
    {
        id   : 'example',
        title: 'Nuestros planes',
        type : 'basic',
        link : '/home/afiliacion'
    }
];
