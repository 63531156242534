/* eslint-disable */
import {DateTime} from 'luxon';

/* Get the current instant */
const now = DateTime.now();

export const responseAsociarEntidades =
    {
        "error": false,
        "descripcion": "",
        "objeto": {
            "id": 112,
            "entidad_tipoentidad": 44,
            "cliente": 32,
            "departamento": null,
            "mod_pag_web": 98,
            "mod_correo": null,
            "mod_fisico": null
        }
    }

