/* eslint-disable */
export const RESULTADOS =
{
    "total_registros": 3,
    "total_registros_eps": 15,
    "total_registros_afp": 16,
    "total_registros_arl": 17,
    "total_registros_ccf": 18,
    "total_registros_completados": 13,
    "total_registros_futuros": 122,
    "total_registros_vencidos": 414,
    "total_registros_completados_eps": 2,
    "total_registros_futuros_eps": 5,
    "total_registros_vencidos_eps": 7,
    "total_registros_completados_afp": 6,
    "total_registros_futuros_afp": 8,
    "total_registros_vencidos_afp": 9,
    "total_registros_completados_arl": 10,
    "total_registros_futuros_arl": 11,
    "total_registros_vencidos_arl": 12,
    "total_registros_completados_ccf": 1,
    "total_registros_futuros_ccf": 2,
    "total_registros_vencidos_ccf": 3,
    "afiliaciones": [
        {
            "identificador": "23456",
            "fecha_carga": '2023/02/16',
            "fecha_ingreso": '2023/01/01',
            "tipo_documento": 'CC',
            "documento": '98381961',
            "nombre_empleado": 'Pedro Perez',
            "empresa": 'Empresa mock',

            "entidad_afp": {
                "id_afiliacion": 12345,
                "nombre": 'Colpensiones',
                "estado_afiliacion": 'Activo',
                "motivo": '',
            },
            "entidad_arl": {
                "id_afiliacion": 24680,
                "nombre": 'Positiva',
                "estado_afiliacion": 'Pendiente respuesta',
                "motivo": 'No ha respondido la entidad'
            },
            "entidad_ccf": {
                "id_afiliacion": 13579,
                "nombre": 'Comfamiliar de Nariño',
                "estado_afiliacion": 'Rechazado',
                "motivo": 'No entregó todos los documentos',
                "beneficiarios": [
                    {
                        "nombre": 'Alejandro',
                        "tipo_identificacion": 'TI',
                        "documento": '108544444',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                    {
                        "nombre": 'Jacobo Alexander',
                        "tipo_identificacion": 'TI',
                        "documento": '1085259521',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                ],
            },
            "entidad_eps": {
                "id_afiliacion": 98765,
                "nombre": 'Nueva eps',
                "estado_afiliacion": 'Radicado',
                "motivo": '',
                "beneficiarios": [
                    {
                        "nombre": 'Alejandro',
                        "tipo_identificacion": 'TI',
                        "documento": '108544444',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                    {
                        "nombre": 'Jacobo Alexander',
                        "tipo_identificacion": 'TI',
                        "documento": '1085259521',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                ],
            }
        },
        {
            "identificador": "23456",
            "fecha_carga": '2023/02/16',
            "fecha_ingreso": '2023/01/01',
            "tipo_documento": 'CC',
            "documento": '98381961',
            "nombre_empleado": 'Pedro Perez',
            "empresa": 'Empresa mock',

            "entidad_afp": {
                "id_afiliacion": 12345,
                "nombre": 'Colpensiones',
                "estado_afiliacion": 'Activo',
                "motivo": '',
            },
            "entidad_arl": {
                "id_afiliacion": 24680,
                "nombre": 'Positiva',
                "estado_afiliacion": 'Pendiente respuesta',
                "motivo": 'No ha respondido la entidad'
            },
            "entidad_ccf": {
                "id_afiliacion": 13579,
                "nombre": 'Comfamiliar de Nariño',
                "estado_afiliacion": 'Rechazado',
                "motivo": 'No entregó todos los documentos',
                "beneficiarios": [
                    {
                        "nombre": 'Alejandro',
                        "tipo_identificacion": 'TI',
                        "documento": '108544444',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                    {
                        "nombre": 'Jacobo Alexander',
                        "tipo_identificacion": 'TI',
                        "documento": '1085259521',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                ],
            },
            "entidad_eps": {
                "id_afiliacion": 98765,
                "nombre": 'Nueva eps',
                "estado_afiliacion": 'Rechazado',
                "motivo": '',
                "beneficiarios": [
                    {
                        "nombre": 'Alejandro',
                        "tipo_identificacion": 'TI',
                        "documento": '108544444',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                    {
                        "nombre": 'Jacobo Alexander',
                        "tipo_identificacion": 'TI',
                        "documento": '1085259521',
                        "parentesco": 'Hijo',
                        "documentos": null,
                    },
                ],
            }
        }
    ]
}









// {
//     "total_registros": 8,
//     "total_registros_eps": 2,
//     "total_registros_afp": 2,
//     "total_registros_arl": 2,
//     "total_registros_ccf": 2,
//     "total_registros_completados": 0,
//     "total_registros_futuros": 8,
//     "total_registros_vencidos": 0,
//     "total_registros_completados_eps": 0,
//     "total_registros_futuros_eps": 2,
//     "total_registros_vencidos_eps": 0,
//     "total_registros_completados_afp": 0,
//     "total_registros_futuros_afp": 2,
//     "total_registros_vencidos_afp": 0,
//     "total_registros_completados_arl": 0,
//     "total_registros_futuros_arl": 0,
//     "total_registros_vencidos_arl": 0,
//     "total_registros_completados_ccf": 0,
//     "total_registros_futuros_ccf": 2,
//     "total_registros_vencidos_ccf": 0,
//     "afiliaciones": [
//         {
//             "identificador": "identificador123",
//             "fecha_carga": "2023-07-05T16:24:09.587720Z",
//             "fecha_ingreso": "2020-01-01T00:00:00Z",
//             "fecha_fin": null,
//             "tipo_documento": "CC",
//             "documento": "1234567890",
//             "nombre_empleado": "Juan Carlos Pérez Gómez",
//             "empresa": "Cliente 1",
//             "beneficiarios": [
//                 {
//                     "primer_nombre": "María",
//                     "numero_identificacion": "987654321",
//                     "codigo_parentesco": "Hija"
//                 }
//             ],
//             "entidad_afp": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             },
//             "entidad_arl": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             },
//             "entidad_ccf": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             },
//             "entidad_eps": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             }
//         },
//         {
//             "identificador": "identificador123",
//             "fecha_carga": "2023-07-05T16:26:41.023276Z",
//             "fecha_ingreso": "2020-01-01T00:00:00Z",
//             "fecha_fin": null,
//             "tipo_documento": "CC",
//             "documento": "1234567890",
//             "nombre_empleado": "Juan Carlos Pérez Gómez",
//             "empresa": "Cliente 1",
//             "beneficiarios": [
//                 {
//                     "primer_nombre": "María",
//                     "numero_identificacion": "987654321",
//                     "codigo_parentesco": "Hija"
//                 }
//             ],
//             "entidad_afp": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             },
//             "entidad_arl": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             },
//             "entidad_ccf": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             },
//             "entidad_eps": {
//                 "id_afiliacion": 1,
//                 "nombre": "Entidad 1",
//                 "estado_afiliacion": "Por Afiliar",
//                 "motivo": null
//             }
//         }
//     ]
// }