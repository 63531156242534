import {Injectable} from '@angular/core';
import {cloneDeep} from 'lodash-es';
import {FuseMockApiService} from '@fuse/lib/mock-api';
import {
    CIUDADES,
    MUNICIPIOS,
    PERIODICIDAD,
    Regimenes,
    TIEMPO_AFILIACION,
    TIEMPO_RESPUESTA, TIPO_PROCESO,
    TiposDocumentos
} from "./data";

@Injectable({
    providedIn: 'root'
})
export class ComboBoxMockApi {

    private _tipoDocumentos: any = TiposDocumentos
    private _regimenes: any = Regimenes
    private _tipoProceso: any = TIPO_PROCESO
    private _periodicidad: any = PERIODICIDAD
    private _tiempoRespuesta: any = TIEMPO_RESPUESTA
    private _tiempoAfiliacion: any = TIEMPO_AFILIACION
    private _ciudades: any = CIUDADES
    private _municipios: any = MUNICIPIOS

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Tipos de documentos - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=tipo_documentos')
            .reply(() => [200, cloneDeep(this._tipoDocumentos)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=regimen')
            .reply(() => [200, cloneDeep(this._regimenes)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=tipo_proceso')
            .reply(() => [200, cloneDeep(this._tipoProceso)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=periodicidad')
            .reply(() => [200, cloneDeep(this._periodicidad)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=tiempo_respuesta')
            .reply(() => [200, cloneDeep(this._tiempoRespuesta)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=tiempo_afiliacion')
            .reply(() => [200, cloneDeep(this._tiempoAfiliacion)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=ciudad')
            .reply(() => [200, cloneDeep(this._ciudades)]);

        this._fuseMockApiService
            .onGet('mock/api/combobox?entidad=municipio&ciudadId=1')
            .reply(() => [200, cloneDeep(this._municipios)]);

    }
}
