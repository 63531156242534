/* eslint-disable */
import {ResultResponseInterface} from "../../../shared/interfaces/result-response.interface";
import {GenericObjectResponseInterface} from "../../../shared/interfaces/generic-object-response.interface";

export const TiposDocumentos: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tipos de documentos",
    objeto: [
        {cod: '1', valor: 'C.C.'},
        {cod: '2', valor: 'C.E.'},
        {cod: '3', valor: 'P.E.'},
        {cod: '4', valor: 'T.I.'},
        {cod: '5', valor: 'C.D.'},
        {cod: '6', valor: 'R.C.'},
        {cod: '7', valor: 'Pasaporte'},
        {cod: '8', valor: 'P.T.'}
    ]
};

export const Regimenes: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tipos de documentos",
    objeto: [
        {cod: 'Contributibo', valor: 'Contributibo'},
        {cod: 'Subsidiado', valor: 'Subsidiado'},
    ]
};
export const TIPO_PROCESO: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tipos de documentos",
    objeto: [
        {cod: 'Manual', valor: 'Manual'},
        {cod: 'Masivo', valor: 'Masivo'},
    ]
};

export const PERIODICIDAD: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tipos de documentos",
    objeto: [
        {cod: 'Semanal', valor: 'Semanal'},
        {cod: 'Quincenal', valor: 'Quincenal'},
        {cod: 'Mensual', valor: 'Mensual'},
    ]
};

export const TIEMPO_RESPUESTA: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tiempos de respuesta",
    objeto: [
        {cod: 'Automatico', valor: 'Automatico'},
        {cod: '2 día Hábil', valor: '2 día Hábil'},
        {cod: '3 día Hábil', valor: '3 día Hábil'},
        {cod: '5 día Hábil', valor: '5 día Hábil'},
        {cod: '7 día Hábil', valor: '7 día Hábil'},
    ]
};

export const TIEMPO_AFILIACION: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tiempos de afilicación",
    objeto: [
        {cod: 'Automático', valor: 'Automático'},
        {cod: '1 día Hábil', valor: '2 día Hábil'},
        {cod: '2 días Hábiles', valor: '2 día Hábil'},
        {cod: '3 días Hábiles', valor: '3 día Hábil'},
        {cod: '4 días Hábiles', valor: '4 día Hábil'},
    ]
};

export const CIUDADES: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tiempos de afilicación",
    objeto: [
        {cod: '1', valor: 'Bogotá'},
        {cod: '2', valor: 'Medellin'},
        {cod: '3', valor: 'cali'},
        {cod: '4', valor: 'Pereira'},
        {cod: '5', valor: 'Barranquilla'},
    ]
};

export const MUNICIPIOS: ResultResponseInterface<{ cod: string, valor: string, ciudadId: string }[]> = {
    error: false,
    descripcion: "Lista de municipios por ciudad",
    objeto: [
        {cod: '1', valor: 'Chia', ciudadId: '1'},
        {cod: '2', valor: 'Cajica', ciudadId: '1'},
        {cod: '3', valor: 'Yumbo', ciudadId: '1'},
        {cod: '4', valor: 'Soledad', ciudadId: '1'},
        {cod: '5', valor: 'Fresno', ciudadId: '1'},
    ]
};

export const TASA_ROTACION: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tasas de rotacion",
    objeto: [
        {cod: '1', valor: 'Menor a 15%'},
        {cod: '2', valor: 'Entre 15% y 40%'},
        {cod: '3', valor: 'mayor a 40%'},
    ]
};
export const OBJETO_SOCIAL: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tasas de OBJETO_SOCIAL",
    objeto: [
        {cod: '1', valor: 'Administracion'},
        {cod: '2', valor: 'Presatcion de servicios'},
        {cod: '3', valor: 'Participacion'},
        {cod: '4', valor: 'Fabricacion'},
        {cod: '5', valor: 'Exportación'},
    ]
};
export const TAMANIO_EMPRESA: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tasas de OBJETO_SOCIAL",
    objeto: [
        {cod: '1', valor: 'Microempresa'},
        {cod: '2', valor: 'Pequeña'},
        {cod: '3', valor: 'mediana'},
        {cod: '4', valor: 'Grande'},
        {cod: '5', valor: 'Multinacional'},
    ]
};
export const FORMA_PAGO: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tasas de OBJETO_SOCIAL",
    objeto: [
        {cod: '1', valor: 'Contado'},
        {cod: '2', valor: 'cheque'},
        {cod: '3', valor: 'Transferencia'},
    ]
};

export const DIAS_PAGO: ResultResponseInterface<GenericObjectResponseInterface[]> = {
    error: false,
    descripcion: "Lista de tasas de OBJETO_SOCIAL",
    objeto: [
        {cod: '1', valor: 'Lunes'},
        {cod: '2', valor: 'Martes'},
        {cod: '3', valor: 'Miercoles'},
    ]
};
