import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import {responseAsociarEntidades} from "./data";

@Injectable({
    providedIn: 'root'
})
export class AsociarEntidadesMockApi
{
    private _responseAsociarEntidades: any = responseAsociarEntidades;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService)
    {
        // Register Mock API handlers
        this.registerHandlers();
    }

    registerHandlers(): void
    {
        this._fuseMockApiService
            .onPost("api/cliente/cli_asc_ent/normal/")
            .reply(() => [200, cloneDeep(this._responseAsociarEntidades)]);
    }
}
