import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { FiltroParametrosService } from '../parametros/filtroParametros.service';
import { LoadingBackdropService } from 'app/shared/services/loading-backdrop.service';
import { UtilPopupService } from '@shared/services/util-popup.service';
import { FormControl } from '@angular/forms';
import { AfiliacionService } from '@shared/services/afiliacion/afiliacion.service';
import Swal from 'sweetalert2';
import { DatosTrazaInterface } from '@features/interfaces/datos_trazabilidad';


@Injectable({
    providedIn: 'root',
})
export class SharedService {
    // Private utility popup service
    private utilPopupService: UtilPopupService;

    // Observable for data
    private dataSubject = new BehaviorSubject<string>('');
    public data$ = this.dataSubject.asObservable();

    // Observables for different entities
    private epsSubject = new BehaviorSubject<boolean>(true);
    private afpSubject = new BehaviorSubject<boolean>(true);
    private ccfSubject = new BehaviorSubject<boolean>(true);
    private arlSubject = new BehaviorSubject<boolean>(true);
    private datosTrazabilidadEpsSubject = new BehaviorSubject<DatosTrazaInterface[]>([]);
    private datosTrazabilidadArlSubject = new BehaviorSubject<DatosTrazaInterface[]>([]);
    private datosTrazabilidadAfpSubject = new BehaviorSubject<DatosTrazaInterface[]>([]);
    private datosTrazabilidadCcfSubject = new BehaviorSubject<DatosTrazaInterface[]>([]);

    // Observables for different entities
    eps$ = this.epsSubject.asObservable();
    afp$ = this.afpSubject.asObservable();
    ccf$ = this.ccfSubject.asObservable();
    arl$ = this.arlSubject.asObservable();
    datosTrazabilidadEps$ = this.datosTrazabilidadEpsSubject.asObservable();
    datosTrazabilidadArl$ = this.datosTrazabilidadArlSubject.asObservable();
    datosTrazabilidadAfp$ = this.datosTrazabilidadAfpSubject.asObservable();
    datosTrazabilidadCcf$ = this.datosTrazabilidadCcfSubject.asObservable();

    // Checkboxes object for different options
    private _idAfiliacionNotificacion = new BehaviorSubject<number | null>(null);
    idAfiliacionNotificacion$ = this._idAfiliacionNotificacion.asObservable();

    checkboxes = {
        checkDocumento: false,
        checkEstado: false,
        checkEmpresa: false,
        checkFecha: false,
        checkEntidad: false,
        checkNovedad: false,
    };


    // Constructor with dependencies injection
    constructor(
        private filtroParametrosService: FiltroParametrosService,
        private _loadingService: LoadingBackdropService,
        private afiliacionS: AfiliacionService
    ) {
        // Inject utility popup service
        this.utilPopupService = inject(UtilPopupService);
    }

    get idAfiliacionNotificacion(): number | null {
        return this._idAfiliacionNotificacion.value;
    }

    set idAfiliacionNotificacion(value: number | null) {
        this._idAfiliacionNotificacion.next(value);
    }

    // Arrays for traceability data of different entities
    datosTrazabilidadEps: DatosTrazaInterface[] = [];
    datosTrazabilidadArl: DatosTrazaInterface[] = [];
    datosTrazabilidadAfp: DatosTrazaInterface[] = [];
    datosTrazabilidadCcf: DatosTrazaInterface[] = [];

    // Information list variable
    informacionList: any;

    // Loading indicators
    loading: boolean = false;
    loadingPopup: boolean = false;

    // Data object
    data: any = {};

    // Checkboxes object
    checks!: any;

    // Form tabs object
    FormTabs = {
        // Filters entity tabs
        TabEntidadesFiltros: {
            eps: true,
            afp: true,
            arl: true,
            ccf: true,
        },
        // Document tab
        TabDocumento: {
            tipoDocumento: null,
            documento: null,
        },
        // State tab
        TabEstado: {
            estado: null,
        },
        // Company tab
        TabEmpresa: {
            empresaSeleccionada: null,
            nivel:{
                nivel_1:null,
                nivel_2:null,
                nivel_3:null,
                nivel_4:null
            }
        },
        // Date tab
        TabFecha: {
            fechaInicio: null,
            fechaFin: null,
        },
        // Entity tab
        TabEntidad: {
            nombre: new FormControl(null),
            eps: null,
            afp: null,
            arl: null,
            ccf: null,
        },
        // Novelties tab
        TabNovedad: {
            tipo: null,
            estado: null,
            filtro: null,
            busqueda: new FormControl(null),
        },
    };

    //Reload
    reload: boolean = false


    // Method to send data to subscribers
    public sendData(data: any): void {
        // Update shared data
        this.dataSubject.next(data);
    }

    // Toggle EPS visibility
    toggleEps(value: boolean) {
        // Update EPS subject with new value
        this.epsSubject.next(value);
    }


    // Toggle AFP visibility
    toggleAfp(value: boolean) {
        // Update AFP subject with new value
        this.afpSubject.next(value);
    }


    // Toggle ARL visibility
    toggleArl(value: boolean) {
        // Update ARL subject with new value
        this.arlSubject.next(value);
    }


    // Toggle CCF visibility
    toggleCcf(value: boolean) {
        // Update CCF subject with new value
        this.ccfSubject.next(value);
    }


    // Update checkboxes for search
    public chexboxesBusqueda(checks: any): any {
        // Assign new checkboxes
        this.checks = checks;
    }


async buscarInformacion(): Promise<void> {
    let eps: boolean;
    let afp: boolean;
    let arl: boolean;
    let ccf: boolean;

        // Check if any checkbox is checked
        if (
            this.verificaCheckSeleccionado()
        ) {
            // Save state of entity checkboxes
            eps = this.FormTabs.TabEntidadesFiltros.eps;
            afp = this.FormTabs.TabEntidadesFiltros.afp;
            arl = this.FormTabs.TabEntidadesFiltros.arl;
            ccf = this.FormTabs.TabEntidadesFiltros.ccf;

        // Build query data
        const data = this.armaConsulta();
        if(!data){
            this._loadingService.hide();
            return
        }
        // Clear information list
        this.informacionList = [];

        try {
            // Show loading indicator
            this._loadingService.show();
            this.loading = true;
            this.loadingPopup = true;

            // Fetch affiliation details from the API
            const resp = await lastValueFrom(this.filtroParametrosService.busquedaControlAfiliacion(data, eps, afp, arl, ccf));

            // Send data to subscribers
            this.sendData(resp);
            // Store response in session storage
            sessionStorage.setItem('response_api', JSON.stringify(resp));
        } catch (error) {

            this._loadingService.hide();

            if(error?.includes('Timeout Exception')){
                // Show error message
                await this.utilPopupService.mostrarMensaje(
                    'Consulta muy extensa, por favor agregar más filtros',
                    'error',
                    '',
                    false
                );
            }else{
                // Show error message
                await this.utilPopupService.mostrarMensaje(
                    'Ocurrió un error al realizar la consulta',
                    'error',
                    '',
                    false
                );
            }

            // Throw error to reject promise
            throw new Error(error);
        } finally {
            // Update loading indicators
            this.loading = false;
            this.loadingPopup = false;
            this._loadingService.hide();
        }
    } else {
        // Show error message if no checkbox is checked
        await this.utilPopupService.mostrarMensaje(
            'Debe chequear al menos una pestaña para realizar la búsqueda',
            'error',
            '',
            false
        );
    }
}


    verificaCheckSeleccionado() {
        if (
            this.checkboxes.checkDocumento ||
            this.checkboxes.checkEstado ||
            this.checkboxes.checkEmpresa ||
            this.checkboxes.checkFecha ||
            this.checkboxes.checkEntidad ||
            this.checkboxes.checkNovedad
        ) {
            return true;
        } else {
            if (this._idAfiliacionNotificacion.getValue() > 0) {
                this.checkboxes.checkDocumento = true;
                return true;
            }
            return false;
        }
    }


    // Build query data based on selected checkboxes and form inputs
    armaConsulta(): {} {
        let documento: string | null = null;
        let tipoDocumento: string | null = null;
        let id_afiliacion: string | null = null;
        let estado: string | null = null;
        let empresa: string | null = null;
        let nivel: any = null;;
        let fechaInicio: any = null;
        let fechaFin: any = null;
        let nombre_entidad: string | null = null;
        let entidad_eps: string | null = null;
        let entidad_afp: string | null = null;
        let entidad_arl: string | null = null;
        let entidad_ccf: string | null = null;
        let tipo_novedad: string | null = null;
        let estado_novedad: string | null = null;
        let filtro_novedad: string | null = null;
        let busqueda_novedad: string | null = null;

        // Check if "Documento" checkbox is checked
        if (this.checkboxes.checkDocumento) {
            if(this.validateObjectValues(this.FormTabs.TabDocumento)){
                tipoDocumento = this.FormTabs.TabDocumento.tipoDocumento;
                if (tipoDocumento != 'id_afiliacion') {
                    documento = this.FormTabs.TabDocumento.documento;
                } else {
                    id_afiliacion = this.FormTabs.TabDocumento.documento;
                    tipoDocumento = null;
                }
            }
            else{
                this.utilPopupService.mostrarMensaje('Valida filtro Documento', 'info', '', false);
                return
            }
        }

        // Check if "Estado" checkbox is checked
        if (this.checkboxes.checkEstado) {
            if(this.FormTabs.TabEstado.estado){
                estado = this.FormTabs.TabEstado.estado;
            }
            else {
                this.utilPopupService.mostrarMensaje('Valida filtro estado', 'info', '', false);
                return
            }
        }

        // Check if "Empresa" checkbox is checked
        if (this.checkboxes.checkEmpresa) {
            const empresaSeleccionada = this.FormTabs.TabEmpresa.empresaSeleccionada;
            if (empresaSeleccionada) {
                empresa = empresaSeleccionada;
                nivel = this.FormTabs.TabEmpresa.nivel
            }else {
                this.utilPopupService.mostrarMensaje('Valida filtro empresas', 'info', '', false);
                console.error('Empresa seleccionada no válida.');
                return
            }
        }

        // Check if "Fecha" checkbox is checked
        if (this.checkboxes.checkFecha) {
            if(this.validateObjectValues(this.FormTabs.TabFecha)){
                fechaInicio = this.FormTabs.TabFecha.fechaInicio ? this.FormTabs.TabFecha.fechaInicio : null;
                if (fechaInicio !== null) {
                    fechaInicio = this.formatDate(fechaInicio);
                }

                fechaFin = this.FormTabs.TabFecha.fechaFin ? this.FormTabs.TabFecha.fechaFin : null;
                if (fechaFin !== null) {
                    fechaFin = this.formatDate(fechaFin);
                }
            }
            else {
                this.utilPopupService.mostrarMensaje('Valida filtro fecha', 'info', '', false);
                return
            }
        }

        // Check if "Entidad" checkbox is checked
        if (this.checkboxes.checkEntidad) {
            let {nombre, eps, afp, arl, ccf} = this.FormTabs.TabEntidad
            if(nombre.value || eps || afp || arl || ccf){
                if (this.FormTabs.TabEntidad.nombre.value && this.FormTabs.TabEntidad.nombre.value !== '') {
                    nombre_entidad = this.FormTabs.TabEntidad.nombre.value.entidad;
                }
                entidad_eps = this.FormTabs.TabEntidad.eps;
                entidad_afp = this.FormTabs.TabEntidad.afp;
                entidad_arl = this.FormTabs.TabEntidad.arl;
                entidad_ccf = this.FormTabs.TabEntidad.ccf;
            }
            else {
                this.utilPopupService.mostrarMensaje('Valida filtro entidades', 'info', '', false);
                return
            }
        }

        // Check if "Novedad" checkbox is checked
        if (this.checkboxes.checkNovedad) {
            tipo_novedad = this.FormTabs.TabNovedad.tipo;
            estado_novedad = this.FormTabs.TabNovedad.estado;
            const empresaSeleccionada = this.FormTabs.TabNovedad.busqueda.value;
            if (empresaSeleccionada) {
                busqueda_novedad = empresaSeleccionada;
            } else {
                console.error('Filtro seleccionada no válida.');
            }
            filtro_novedad = this.FormTabs.TabNovedad.filtro;
        }

        // Build query data object
        const data = {
            tipo_documento: tipoDocumento,
            numero_documento: documento,
            estado: estado,
            empresa: empresa,
            nivel: nivel,
            fecha_inicio: fechaInicio,
            fecha_fin: fechaFin,
            id_afiliacion: id_afiliacion,
            entidades: {
                entidad_eps: entidad_eps,
                entidad_afp: entidad_afp,
                entidad_arl: entidad_arl,
                entidad_ccf: entidad_ccf,
                nombre_entidad: nombre_entidad,
            },
            tipo_novedad: tipo_novedad?Number(tipo_novedad):tipo_novedad,
            estado_novedad: estado_novedad?Number(estado_novedad):estado_novedad,
            busqueda_novedad,
            filtro_novedad,
        };
        return data;
    }

    // Helper function to format date
    private formatDate(date: Date): string {
        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const anio = date.getFullYear();
        return `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${anio}`;
    }

/**
 * Function to calculate progress percentage based on affiliation state
 * @param estado - affiliation state
 * @returns {string} - progress percentage
 */
    calculaPorcentajeProceso(estado: string): string {
        switch (estado) {
            case '1': // To affiliate
                return '0';
            case '2': // Pending response
                return '50';
            case '3': // Registered
                return '75';
            case '4': // Active
                return '100';
            case '5': // Pending beneficiary
                return '0'; // Request this percentage
            case '6': // Cancellations
                return '25'; // Request this percentage
            case '7': // Rejection
                return '25';
            case '10': //Pendiente por firmar
                return '0';
            default:
                return '0';
        }
    }

/**
    * Function to get button name based on affiliation state
    * @param estado - affiliation state
    * @returns {string} - button name
    */
    /*
   * Get button name based on affiliation state
   * 1: To affiliate
   * Radicated 3, Pending Response 2, and Pending Beneficiary 5.
   * View affiliation
   * Active 4, Rejected 7
   * */
    getButtonNameByState(estado: string): string {
        if (
            estado === '1' ||
            estado === '2' ||
            estado === '3' ||
            estado === '5'
        ) {
            return 'Ejecutar afiliación';
        } else if (estado === '4' || estado === '7' || estado === '6' || estado === '8') {
            return 'Ver afiliación';
        }
    }


    /**
 * Function to calculate color code based on affiliation state
 * @param estado - affiliation state
 * @returns {string} - color code
 */
    /*
    * Get color code based on affiliation state
    * 1: To affiliate
    * 2: Pending response
    * 3: Radicated
    * 4: Active
    * 5: Pending Beneficiary
    * 6: Cancellations
    * 7: Rejection
    * */
    getColor(estado: any): string {
        // Check if the state is defined
        if (estado) {
            // Switch based on the affiliation state
            switch (estado) {
                case '1': // To affiliate
                case 'Por afiliar':
                    return '#ffc300';   // Yellow color
                case '2': // Pending response
                case 'Pendiente respuesta':
                    return '#8b0d50';   // Purple color
                case '3': // Radicated
                case 'Radicado':
                    return '#6523ff';   // Blue color
                case '4': // Active
                case 'Activo':
                    return '#009f4b';   // Green color
                case '5': // Pending Beneficiary
                case 'Pendiente beneficiario':
                    return '#8b0d50';   // Purple color (Request this percentage)
                case '6': // Cancellations
                case 'Anulaciones':
                    return '#2f2f2f';   // Dark color (Request this percentage)
                case '7': // Rejection
                case 'Rechazo':
                    return '#e04e4e';
                case '10': //Pendiente por firmar
                case 'Pendiente por firmar':
                    return '#1e42c0';
                default:
                    return '#e04e4e';   // Default red color for undefined states
            }
        }
    }

    /**
 * Function to get affiliation state based on code
 * @param estado - affiliation state code
 * @returns {string} - affiliation state
 */
    /*
    * Get affiliation state based on code
    * 1: To affiliate
    * 2: Pending response
    * 3: Radicated
    * 4: Active
    * 5: Pending Beneficiary
    * 6: Cancellations
    * 7: Rejection
    * */
    getEstado(estado: any): string {
        // Check if the state is defined
        if (estado) {
            // Switch based on the lowercased affiliation state
            switch (estado.toLowerCase()) {
                case '1':
                    return 'Por afiliar';   // To affiliate
                case '2':
                    return 'Pendiente respuesta';   // Pending response
                case '3':
                    return 'Radicado';   // Radicated
                case '4':
                    return 'Activo';   // Active
                case '5':
                    return 'Pendiente beneficiario';   // Pending Beneficiary
                case '6':
                    return 'Anulaciones';   // Cancellations
                case '7':
                    return 'Rechazo';
                case '10':
                    return 'Pendiente por firmar';
                default:
                    return 'Indeterminado';   // Default for undefined states
            }
        }
    }

    /**
 * Function to get cancellation state based on code
 * @param estado - cancellation state code
 * @returns {string} - cancellation state
 */
    getEstadoAnulacion(estado: any): string {
        // Check if the state is not defined
        if (!estado) {
            return '';   // Return empty string
        }
        // Switch based on the cancellation state
        switch (estado) {
            case '1':
                return 'Sin Procesar';   // Unprocessed
            case '2':
                return 'Pendiente respuesta';   // Pending response
            case '3':
                return 'Anulación Rechazada';   // Cancellation rejected
            case '4':
                return 'Anulado';   // Cancelled
            default:
                return 'Indeterminado';   // Default for undefined states
        }
    }


/**
 * Function to calculate color code based on cancellation state
 * @param estado - cancellation state code
 * @returns {string} - color code
 */
    getColorAnulacion(estado: any): string {
        // Check if the state is not defined
        if (!estado) {
            return '';   // Return empty string
        }
        // Switch based on the cancellation state
        switch (estado) {
            case '1':
                return '#2F2F2F';   // Unprocessed - Dark Gray
            case '2':
                return '#676767';   // Pending response - Light Gray
            case '3':
                return '#E04E4E';   // Cancellation rejected - Red
            case '4':
                return '#009F4B';   // Cancelled - Green
            default:
                return '#FFF';   // Default for undefined states - White
        }
    }

    /**
 * Function to calculate color code based on entity state
 * @param estado - entity state
 * @returns {string} - color code
 */
    getColorEstadoEntidad(estado: string): string {
        if (!estado) {
            return '';
        }
        switch (estado) {
            case 'Error':
                return '#E04E4E';
            case 'Inscrito':
                return '#009F4B';
            default:
                return '#ffc300';
        }
    }


    /**
 * Function to get entity state based on code
 * @param estado - entity state code
 * @returns {string} - entity state
 */
    getEstadoEntidad(estado: string): string {
        if (!estado) {
            return '';
        }
        return estado;
    }


    /**
 * Function to calculate color code based on novelty state
 * @param estado - novelty state code
 * @returns {string} - color code
 */
    getColorEstadosNovedad(estado: any): string {
        // Check if the state is not defined
        if (!estado) {
            return '';   // Return empty string
        }
        // Switch based on the novelty state
        switch (estado) {
            case '1':
                return '#ffc300';   // New - Yellow
            case '2':
                return '#8b0d50';   // In progress - Purple
            case '3':
                return '#6523ff';   // Completed - Green
            case '4':
                return '#009F4B';   // Cancellation requested - Blue
            case '5':
                return '#E04E4E';   // Rejected - Red
            default:
                return '#FFF';   // Default for undefined states - White
        }
    }


    /**
 * Function to get novelty state based on code
 * @param estado - novelty state code
 * @returns {string} - novelty state
 */
    getEstadoNovedad(estado: any): string {
        // Check if the state is not defined
        if (!estado) {
            return '';   // Return empty string
        }
        // Switch based on the novelty state
        switch (estado) {
            case '1':
                return 'Sin Procesar';   // Unprocessed
            case '2':
                return 'Pendiente respuesta';   // Pending response
            case '3':
                return 'Radicado';   // Radicated
            case '4':
                return 'Novedad exitosa';   // Successful novelty
            case '5':
                return 'Novedad Rechazada';   // Rejected novelty
            default:
                return 'Indeterminado';   // Default for undefined states
        }
    }


    /**
 * Function to convert base64 string to Blob
 * @param base64 - base64 string
 * @param contentType - content type of the Blob
 * @returns {Blob} - Blob object
 */
    base64ToBlob(base64: string, contentType: string): Blob {
        // Decode the base64 string
        const byteCharacters = atob(base64);
        // Initialize an empty array to store byte arrays
        const byteArrays = [];

        // Iterate over the byte characters in chunks of 512
        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            // Slice the byte characters into chunks
            const slice = byteCharacters.slice(offset, offset + 512);

            // Convert each character to its Unicode code point
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            // Create a Uint8Array from the byte numbers
            const byteArray = new Uint8Array(byteNumbers);
            // Push the byte array into the byte arrays array
            byteArrays.push(byteArray);
        }

        // Create a Blob from the byte arrays with the specified content type
        return new Blob(byteArrays, { type: contentType });
    }


    /**
 * Function to download Blob as a file
 * @param b64 - base64 string
 * @param nombreArchivo - file name
 * @param type - content type of the Blob
 */
    downloadBlobAsFile(b64: any, nombreArchivo: string, type: string) {
        // Decode the base64 string to byte characters
        const byteCharacters = atob(b64);
        // Initialize an array to store byte numbers
        const byteNumbers = new Array(byteCharacters.length);

        // Convert each character to its Unicode code point
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        // Create a Uint8Array from the byte numbers
        const byteArray = new Uint8Array(byteNumbers);
        // Create a Blob from the byte array with the specified content type
        const blob = new Blob([byteArray], {
            type: type,
        });

        // Create a URL for the Blob
        const urlArchivo = window.URL.createObjectURL(blob);
        // Create a link element to trigger the download
        const linkDescarga = document.createElement('a');
        // Set the href attribute of the link to the Blob URL
        linkDescarga.href = urlArchivo;
        // Set the download attribute to the file name
        linkDescarga.download = nombreArchivo;
        // Append the link to the document body
        document.body.appendChild(linkDescarga);
        // Simulate a click on the link to start the download
        linkDescarga.click();
        // Revoke the Blob URL to release the resources
        window.URL.revokeObjectURL(urlArchivo);
        // Remove the link from the document body
        document.body.removeChild(linkDescarga);
    }


    /**
 * Function to send affiliation request
 * @param formularioGeneral - general affiliation form data
 * @param masivo - flag indicating mass upload
 */
    enviarAfiliacion(formularioGeneral: any, masivo: boolean = false) {
        let label = 'afiliación';

        // Set label depending on whether it's a mass upload
        if (masivo) {
            label = 'carga masiva';
            console.log(formularioGeneral);
        } else {
            console.log(formularioGeneral.getRawValue());
        }
        const currentDate = new Date();
        const currentDateString = currentDate.toLocaleDateString();
        const currentTimeString = currentDate.toLocaleTimeString();

        // Display Swal modal for user input
        Swal.fire({
            html: `<div style="font-family: 'roboto'; margin-bottom:22px"><p id="tituloModal" style="font-size: 30px; font-weight: 400; color:#F5BF0D">Nombre a la ${label} <span style="color: #fff">para generar la solicitud</span></p></div>
         <div style="display: flex; justify-content: center">
         <p id='labelNombreAfiliacion' style="font-size: 20px; font-weight: 400; color:#fff; margin-right: 8px">Escriba el nombre de la ${label}:</p>
         <input id="inputAfiliacion" type="text" style="border: 1px solid white; width: 201px; height: 21px; margin-right: 21px; color: #F5BF0D; font-weight: 700; font-size: 12px; text-align: center" [(value)]="nombreAfiliacion"/>
         <p style="font-size: 20px; font-weight: 400; color:#fff; margin-right: 8px">Fecha:</p>
         <input type="text" style="border: 1px solid white; width: 102px; height: 21px; margin-right: 21px; color: #F5BF0D; font-weight: 700; font-size: 12px; text-align: center" value="${currentDateString}" disabled/>
         <p style="font-size: 20px; font-weight: 400; color:#fff; margin-right: 8px">Hora:</p>
         <input type="text" style="border: 1px solid white; width: 102px; height: 21px; margin-right: 21px; color: #F5BF0D; font-weight: 700; font-size: 12px; text-align: center" value="${currentTimeString}" disabled/>
         </div><div id="containerConfirmacion" style="margin-top: 40px; margin-bottom: 40px; display: flex; justify-content: center"></div><div id="containerOpciones" style="margin-top: 40px; margin-bottom: 40px; display: flex; flex-direction: column; align-items: center; gap: 8px"></div>`,
            width: '60%',
            background: '#6523ff',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
        }).then((result) => {
            if (
                result.dismiss === Swal.DismissReason.esc ||
                result.dismiss === Swal.DismissReason.backdrop
            ) {
                return; // If canceled or closed, no additional action is taken
            }
        });

        // Add event listener for keyup on input field
        const inputAfiliacion = document.getElementById(
            'inputAfiliacion'
        ) as HTMLInputElement;
        const containerConfirmacion = document.getElementById(
            'containerConfirmacion'
        );
        const containerOpciones = document.getElementById('containerOpciones');
        const tituloModal = document.getElementById('tituloModal');
        const labelNombreAfiliacion = document.getElementById(
            'labelNombreAfiliacion'
        );

        inputAfiliacion.addEventListener('keyup', (event) => {
            const inputValue = (event.target as HTMLInputElement).value;
            let excel = undefined;
            if (event.key === 'Enter' && !!inputValue) {
                if (masivo) {
                    this._loadingService.show();
                    // Call service method for mass upload
                    this.afiliacionS
                        .crearAfiliacionMasivo('masivo-1', {
                            nombre_masivo: inputValue,
                            afiliaciones: formularioGeneral,
                        })
                        .subscribe({
                            next: (data) => {
                                excel = data.excel;
                                this._loadingService.hide();
                            },
                            error: (e) => {
                                this._loadingService.hide();
                            },
                        });
                } else {
                    // Call service method for single affiliation
                    this.afiliacionS
                        .crearAfiliacion(formularioGeneral.getRawValue())
                        .subscribe((data) => {
                            console.log(data);
                        });
                }

                inputAfiliacion.disabled = true;
                tituloModal.textContent = `${label.charAt(0).toUpperCase() + label.slice(1)
                    } exitosa`;
                labelNombreAfiliacion.textContent = `Nombre de la ${label}`;

                // Display success message and options
                const successMessage = document.createElement('p');
                successMessage.textContent = `Su ${label} ${inputValue} ha sido enviada con éxito, visite nuestra sección de estado de afiliaciones, para visualizar el estatus y descargar documentos correspondientes de la ${label}.`;
                successMessage.style.fontSize = '20px';
                successMessage.style.fontWeight = '400';
                successMessage.style.color = '#fff';
                successMessage.style.textAlign = 'center';
                successMessage.style.width = '70%';

                const buttonVerEstado = document.createElement('button');
                const buttonOtraAfiliacion = document.createElement('button');

                buttonVerEstado.textContent = `Ver estado de ${label}`;
                buttonVerEstado.style.backgroundColor = '#fff';
                buttonVerEstado.style.color = '#6523FF';
                buttonVerEstado.style.width = '379px';
                buttonVerEstado.style.height = '30px';

                buttonOtraAfiliacion.textContent = `Hacer otra ${label}`;
                buttonOtraAfiliacion.style.backgroundColor = '#fff';
                buttonOtraAfiliacion.style.color = '#000';
                buttonOtraAfiliacion.style.width = '379px';
                buttonOtraAfiliacion.style.height = '30px';

                // Add event listeners to buttons
                buttonVerEstado.addEventListener('click', () => {
                    console.log('Ver estado clicked');
                });

                buttonOtraAfiliacion.addEventListener('click', () => {
                    location.reload();
                });

                containerConfirmacion.appendChild(successMessage);

                if (masivo) {
                    // If mass upload, add button to download Excel file
                    const buttonDescargarExcel =
                        document.createElement('button');
                    buttonDescargarExcel.textContent = `Descargar IDs de confirmación`;
                    buttonDescargarExcel.style.backgroundColor = '#fff';
                    buttonDescargarExcel.style.color = '#6523FF';
                    buttonDescargarExcel.style.width = '379px';
                    buttonDescargarExcel.style.height = '30px';
                    buttonDescargarExcel.addEventListener('click', () => {
                        const blob = this.base64ToBlob(
                            excel,
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        );
                        const url: string = window.URL.createObjectURL(blob);
                        const a: HTMLAnchorElement =
                            document.createElement('a');
                        a.href = url;
                        a.download = 'ids.xlsx';
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    });
                    containerOpciones.style.flexDirection = 'column';
                    containerOpciones.style.alignItems = 'center';
                    const subContainer = document.createElement('div');
                    subContainer.style.display = 'flex';
                    subContainer.style.flexDirection = 'row';
                    subContainer.style.justifyContent = 'space-between';
                    subContainer.style.width = '80%';

                    buttonVerEstado.style.width = '49%';
                    buttonDescargarExcel.style.width = '49%';

                    subContainer.appendChild(buttonVerEstado);
                    subContainer.appendChild(buttonDescargarExcel);
                    containerOpciones.appendChild(subContainer);

                    buttonOtraAfiliacion.style.width = '80%';
                    containerOpciones.appendChild(buttonOtraAfiliacion);
                } else {
                    containerOpciones.appendChild(buttonVerEstado);
                    containerOpciones.appendChild(buttonOtraAfiliacion);
                }

                console.log(`Nombre de ${label}:`, inputValue);
            }
        });
    }

    /*
   * Update EPS traceability data
   * @param nuevosDatos - New EPS traceability data
   */
    actualizarDatosTrazabilidadEps(nuevosDatos: DatosTrazaInterface[]) {
        this.datosTrazabilidadEpsSubject.next(nuevosDatos);
    }

    /*
     * Update ARL traceability data
     * @param nuevosDatos - New ARL traceability data
     */
    actualizarDatosTrazabilidadArl(nuevosDatos: DatosTrazaInterface[]) {
        this.datosTrazabilidadArlSubject.next(nuevosDatos);
    }

    /*
     * Update AFP traceability data
     * @param nuevosDatos - New AFP traceability data
     */
    actualizarDatosTrazabilidadAfp(nuevosDatos: DatosTrazaInterface[]) {
        this.datosTrazabilidadAfpSubject.next(nuevosDatos);
    }

    /*
     * Update CCF traceability data
     * @param nuevosDatos - New CCF traceability data
     */
    actualizarDatosTrazabilidadCcf(nuevosDatos: DatosTrazaInterface[]) {
        this.datosTrazabilidadCcfSubject.next(nuevosDatos);
    }

    validateObjectValues(obj: Record<string, any>): boolean {
        for (const key in obj) {
          if (obj[key] === null) {
            return false;
          }
        }
        return true;
    }
}
