import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageComponent } from '@shared/components/error-message/error-message.component';
import { MainHeaderComponent } from '@shared/components/main-header/main-header.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BaseAffiliationContainerComponent } from '@shared/components/base-affiliation-container/base-affiliation-container.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { CompanyFormComponent } from '@shared/components/base-affiliation-container/components/company-form/company-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AffiliationFuaFormComponent } from '@shared/components/base-affiliation-container/components/affiliation-fua-form/affiliation-fua-form.component';
import { AffiliationFormComponent } from '@shared/components/base-affiliation-container/components/affiliation-form/affiliation-form.component';
import { EmployeeFormComponent } from '@shared/components/base-affiliation-container/components/employee-form/employee-form.component';
import { EmployeeFuaFormComponent } from '@shared/components/base-affiliation-container/components/employee-fua-form/employee-fua-form.component';
import { BeneficiariesContainerComponent } from '@shared/components/base-affiliation-container/components/beneficiaries-container/beneficiaries-container.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { BeneficiaresDataFormComponent } from '@shared/components/base-affiliation-container/components/beneficiares-data-form/beneficiares-data-form.component';
import { BeneficiaresDocumentsFormComponent } from '@shared/components/base-affiliation-container/components/beneficiares-documents-form/beneficiares-documents-form.component';
import { MomentService } from '@utils/functions/momentService';
import { MaterialModule } from './material.module';
import { DepartmentsOperationsModalComponent } from './components/departments-operations-modal/departments-operations-modal.component';
import { TipoDocFilter } from '@shared/pipes/tipoDocFilter';
import { UpperCaseDirective } from '@shared/directives/upper-case.directive';
import { OnlyNumbersDirective } from '@shared/directives/only-numbers.directive';

// Pipes
import { DateformatPipe } from './pipes/dateformat.pipe';

@NgModule({
    declarations: [
        ErrorMessageComponent,
        MainHeaderComponent,
        BaseAffiliationContainerComponent,
        CompanyFormComponent,
        AffiliationFuaFormComponent,
        AffiliationFormComponent,
        EmployeeFormComponent,
        EmployeeFuaFormComponent,
        BeneficiariesContainerComponent,
        BeneficiaresDataFormComponent,
        BeneficiaresDocumentsFormComponent,
        DepartmentsOperationsModalComponent,
        TipoDocFilter,
        UpperCaseDirective,
        OnlyNumbersDirective,
        DateformatPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatCheckboxModule,
        MatCardModule,
        MatStepperModule,
        MatButtonModule,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        MatIconModule,
        MatInputModule,
        MatDatepickerModule,
        MatExpansionModule,
        MaterialModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorMessageComponent,
        MainHeaderComponent,
        NgxFileDropModule,
        BaseAffiliationContainerComponent,
        TipoDocFilter,
        UpperCaseDirective,
        OnlyNumbersDirective,
        DateformatPipe,
    ],
    providers: [MomentService],
})
export class SharedModule {}
