import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, catchError, firstValueFrom, interval, Observable, switchMap, tap, throwError } from 'rxjs';
import { NotificationInterface } from 'app/layout/common/notifications/notifications.types';
import { Api } from '@core/models/Api.model';
import { SharedService } from '@features/afiliacion/control-afiliacion/services/shared/shared.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    //    private _notifications: ReplaySubject<NotificationInterface> = new ReplaySubject<NotificationInterface>(1);
    private _notifications: BehaviorSubject<NotificationInterface> = new BehaviorSubject<NotificationInterface>(null);
    private _pollingInterval = 600000; // Intervalo de polling en milisegundos (1 hora)

    private _unreadCount = new BehaviorSubject<number | null>(null);
    unreadCount$ = this._unreadCount.asObservable();

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private sharedService: SharedService,
    ) {
        this.loadNotifications();
        this.setupPolling();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<NotificationInterface> {
        return this._notifications.asObservable();
    }

    get unreadCount(): number | null {
        return this._unreadCount.value;
      }
    
    set unreadCount(value: number | null) {
        if (value !== this._unreadCount.value) {  // Evita actualizar si el valor es el mismo
          this._unreadCount.next(value);
        }
      }

    updateUnreadCount(count: number | null): void {
        this.unreadCount = count;
      }

    /**
         * Cargar todas las notificaciones
         */
    /**
 * Loads all notifications.
 *
 * @param page - Page number for pagination (default is 1).
 * @param pageSize - Page size for pagination (default is 10).
 *
 * @returns {void}
 *
 * @throws {Error} - Throws an error if there is a problem loading the notifications.
 */
    loadNotifications(page: number = 1, pageSize: number = 10): void {
        this._httpClient.get<NotificationInterface>(`${Api.BaseUrlApp}notificaciones/?page=${page}&page_size=${pageSize}`)
            .pipe(
                catchError(error => {
                    console.error('Error fetching notifications:', error);
                    return throwError(() => new Error(error));
                })
            )
            .subscribe((notifications) => {
                this._notifications.next(notifications);
            });
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    /**
 * Retrieves all notifications from the server.
 *
 * @param page - The page number for pagination. Default is 1.
 * @param pageSize - The number of notifications per page. Default is 10.
 *
 * @returns {Observable<NotificationInterface>} - An observable that emits the retrieved notifications.
 *
 * @throws {Error} - Throws an error if there is a problem fetching the notifications.
 *
 * @remarks
 * This method uses the HttpClient to make a GET request to the server's notification endpoint.
 * It then processes the response using RxJS operators to update the notifications subject.
 *
 */
    getAll(page: number, pageSize: number): Observable<NotificationInterface> {
        return this._httpClient.get<NotificationInterface>(`${Api.BaseUrlApp}notificaciones/?page=${page}&page_size=${pageSize}`).pipe(
            tap((notifications) => {
                this._notifications.next(notifications);
            })
        );
    }

    /**
     * Configurar el intervalo de polling para actualizar las notificaciones
     */
    /**
 * Configures the polling interval to update notifications.
 *
 * @remarks
 * This method uses RxJS operators to set up a polling mechanism.
 * It sends a GET request to the server's notification endpoint every `_pollingInterval` milliseconds.
 * If there is an error during the request, it logs the error and re-throws it.
 * If the request is successful, it updates the notifications subject with the new notifications data.
 * It also calls the `updateUnreadCount` method to update the unread count in the shared service.
 *
 * @private
 */
    private setupPolling(): void {
        interval(this._pollingInterval)
            .pipe(
                switchMap(() => this._httpClient.get<NotificationInterface>(`${Api.BaseUrlApp}notificaciones/`)),
                catchError(error => {
                    return throwError(() => new Error(error));
                })
            )
            .subscribe((notifications) => {
                this._notifications.next(notifications);
                this.updateUnreadCount(notifications.count);
            });
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    /**
 * Updates a single notification by its ID.
 *
 * @param id - The ID of the notification to update.
 *
 * @returns {Promise<any>} - A promise that resolves with the updated notification data.
 *
 * @throws {Error} - Throws an error if there is a problem updating the notification.
 *
 * @remarks
 * This method sends a PUT request to the server's notification endpoint with the ID of the notification to update.
 * It then processes the response using the `firstValueFrom` operator to extract the updated notification data.
 * If there is an error during the request, it logs the error and re-throws it.
 *
 */
    async update(id: number): Promise<any> {
        try {
            const updatedNotification = await firstValueFrom(
                this._httpClient.put(`${Api.BaseUrlApp}notificaciones/${id}/update_leido/`, {
                    leido: true
                })
            );

            return updatedNotification;
        } catch (error) {
            console.error('Error updating notification:', error);
            throw error; // Re-throw the error to be handled by the caller
        }
    }



    /**
 * Marks all notifications as read.
 *
 * @remarks
 * This method sends a PUT request to the server's notification endpoint to update multiple notifications at once.
 * It expects an array of notification objects with their IDs and sets the 'leido' property to true.
 *
 * @param notifications - An array of notification objects to be updated.
 * Each object should have a 'id' property representing the notification ID.
 *
 * @returns {Promise<any>} - A promise that resolves with the updated notification data.
 *
 * @throws {Error} - Throws an error if there is a problem updating the notifications.
 *
 */
    async markAllAsRead(notifications: any): Promise<any> {
        try {
            const updatedNotification = await firstValueFrom(
                this._httpClient.put(`${Api.BaseUrlApp}notificaciones/update_multiple_leido/`, notifications)
            );

            return updatedNotification;
        } catch (error) {
            console.error('Error updating notification:', error);
            throw error; // Re-throw the error to be handled by the caller
        }
    }

}
