import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatButton } from '@angular/material/button';
import { Subject, Subscription, firstValueFrom, takeUntil } from 'rxjs';
import { NotificationResultsInterface } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { Router } from '@angular/router';
import { SharedService } from '@features/afiliacion/control-afiliacion/services/shared/shared.service';
import { LoadingBackdropService } from '@shared/services/loading-backdrop.service';
import { UtilPopupService } from '@shared/services/util-popup.service';

@Component({
    selector: 'notifications',
    templateUrl: './notifications.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'notifications'
})
export class NotificationsComponent implements OnInit, OnDestroy {
    @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
    @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

    notifications: NotificationResultsInterface[];
    private _overlayRef: OverlayRef;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private unreadCountSubscription: Subscription;
    public unreadCount: number;
    

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _notificationsService: NotificationsService,
        private _sharedService: SharedService,
        private _overlay: Overlay,
        private _viewContainerRef: ViewContainerRef,
        private router: Router,
        private _loadingService: LoadingBackdropService,
        private _utilPopupService: UtilPopupService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
 * Initializes the component.
 * Fetches notifications and subscribes to unread count changes.
 */
    ngOnInit(): void {

        /**
         * Subscribes to unread count changes from SharedService.
         * Updates the unreadCount property and calls updateUnreadCount method.
         */
        this.unreadCountSubscription = this._notificationsService.unreadCount$.subscribe((count) => {
            this.updateUnreadCount(count ?? null);
            this.unreadCount = this._notificationsService.unreadCount;
            // Additional logic if needed
        });

        /**
         * Subscribes to notifications changes from NotificationsService.
         * Updates the notifications and unreadCount properties and calls updateUnreadCount method.
         */
        this._notificationsService.notifications$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(
                notifications => {
                    this.notifications = notifications?.results;
                    this.updateUnreadCount(notifications?.count ?? null);
                    this.unreadCount = this._notificationsService.unreadCount;
                    this._changeDetectorRef.markForCheck(); 
                }
            );
            
            // Fetch notifications initially
            this.getNotificaciones(1, 10);
            
            // Mark the component for check to reflect changes
            this._changeDetectorRef.markForCheck();
    }

    /**
 * Fetches notifications from the server and updates the notifications and unreadCount properties.
 *
 * @param page - The page number for pagination.
 * @param pageSize - The number of notifications per page.
 *
 * @returns A Promise that resolves to the fetched notifications.
 *
 * @throws Will throw an error if there is a problem fetching notifications.
 */
    getNotificaciones(page: number, pageSize: number): Promise<any> {
        return firstValueFrom(this._notificationsService.getAll(page, pageSize)).then(response => {
            // Assuming response contains the structure as shown
            this.notifications = response.results;
            this.updateUnreadCount(response.count)
            this._changeDetectorRef.markForCheck();

        }).catch(error => {
            console.error('Error fetching notifications', error);
            throw error;  // Rethrow the error after logging it
        });
    }


    /**
     * On destroy
     */
    /**
 * On destroy lifecycle hook.
 * Cleans up subscriptions and disposes the overlay.
 */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions to prevent memory leaks
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();

        // Check if there is an unreadCountSubscription and unsubscribe from it
        if (this.unreadCountSubscription) {
            this.unreadCountSubscription.unsubscribe();
        }

        // Check if there is an overlayRef and dispose it to free up resources
        if (this._overlayRef) {
            this._overlayRef.dispose();
        }
    }


    // Método para actualizar `unreadCount` en `SharedService`
    /**
 * Updates the unreadCount property in SharedService with the current unreadCount.
 *
 * @param newCount - The new unread count to be updated in SharedService.
 *                   It can be null if the unread count is not available.
 *
 * @returns {void} - This method does not return any value.
 *
 * @throws Will not throw any exceptions.
 *
 * @example
 * ```typescript
 * // Assuming unreadCount is 10
 * this.updateUnreadCount(10);
 * // Now, SharedService.unreadCount will be 10
 * ```
 */
    updateUnreadCount(newCount: number | null): void {
        this._notificationsService.updateUnreadCount(newCount);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Open the notifications panel
     */
    openPanel(): void {
        // Return if the notifications panel or its origin is not defined
        if (!this._notificationsPanel || !this._notificationsOrigin) {
            return;
        }

        // Create the overlay if it doesn't exist
        if (!this._overlayRef) {
            this._createOverlay();
        }

        // Attach the portal to the overlay
        this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
    }


    async filtraAfiliacion(notification: NotificationResultsInterface) {
        this.router.navigate(['/control-afiliacion/control_afiliacion']);
        this._sharedService.idAfiliacionNotificacion = notification.afiliacion_id;

        try {
            // Cambia la notificación a leída y espera a que se complete
            await this._notificationsService.update(notification.id);

            // Obtener notificaciones y cerrar el panel
            this.getNotificaciones(1, 10);
            this.closePanel();
        } catch (error) {
            console.error('Error al actualizar la notificación:', error);
            // Manejar el error si es necesario
        }
    }

    verTodas() {
        this.closePanel();
        this.router.navigate(['/home/notificaciones']);

    }


    /**
     * Close the notifications panel
     */
    closePanel(): void {
        this._overlayRef.detach();
    }

    /**
     * Mark all notifications as read
     */
    async markAllAsRead() {
        this._loadingService.show();
        try {
            await this.getNotificaciones(1, this._notificationsService.unreadCount);
            // Mark all as read
            const notificationsDelete = {
                notificaciones: this.notifications.map(notification => ({
                    id: notification.id,
                    leido: true
                }))
            };
            await this._notificationsService.markAllAsRead(notificationsDelete);
            await this.getNotificaciones(1, 10);
        } catch (error) {
            this._loadingService.hide();
            this._utilPopupService.mostrarMensaje('Error al marcar todas las notificaciones como leídas', 'error', 'Error', false);
        } finally {
            this._loadingService.hide();
        }
    }

    /**
     * Toggle read status of the given notification
     */
    // toggleRead(notification: NotificationResultsInterface): void {
    //     // Toggle the read status
    //     notification.leido = !notification.leido;
    //     // Update the notification
    //     this._notificationsService.update(notification.id).subscribe();
    // }

    /**
     * Delete the given notification
     */
    async delete(notification: NotificationResultsInterface) {
        try {
            // Cambia la notificación a leída y espera a que se complete
            await this._notificationsService.update(notification.id);

            // Obtener notificaciones y cerrar el panel
            this.getNotificaciones(1, 10);
        } catch (error) {
            console.error('Error al actualizar la notificación:', error);
            // Manejar el error si es necesario
        }
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create the overlay
     */
    private _createOverlay(): void {
        // Create the overlay
        this._overlayRef = this._overlay.create({
            hasBackdrop: true,
            backdropClass: 'fuse-backdrop-on-mobile',
            scrollStrategy: this._overlay.scrollStrategies.block(),
            positionStrategy: this._overlay.position()
                .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
                .withLockedPosition(true)
                .withPush(true)
                .withPositions([
                    {
                        originX: 'start',
                        originY: 'bottom',
                        overlayX: 'start',
                        overlayY: 'top'
                    },
                    {
                        originX: 'start',
                        originY: 'top',
                        overlayX: 'start',
                        overlayY: 'bottom'
                    },
                    {
                        originX: 'end',
                        originY: 'bottom',
                        overlayX: 'end',
                        overlayY: 'top'
                    },
                    {
                        originX: 'end',
                        originY: 'top',
                        overlayX: 'end',
                        overlayY: 'bottom'
                    }
                ])
        });

        // Detach the overlay from the portal on backdrop click
        this._overlayRef.backdropClick().subscribe(() => {
            this._overlayRef.detach();
        });
    }
}
